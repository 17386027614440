<template>
  <div class="Organ bg-white">
    <Row class="px-1">
      <i-col span="18" class="align-justify">
        <!-- <Button type="default" icon="md-refresh" size="large" class="mr-1"></Button> -->
        <Button type="default" icon="md-add" size="large" class="mr-1" @click="addCompany"></Button>
        <i-input v-model="inputValue" size="large" style="width: 18.75rem" class="el-inline mr-1" placeholder="请输入单位名称进行查询">
          <Button slot="append" icon="md-search" style="width: 2.5rem" @click="getCompanyList"></Button>
        </i-input>
        <!-- <Button type="default" size="large" class="mr-1">批量{{deOrRe}}</Button>
        <Button type="default" size="large" class="mr-1">批量导出</Button>-->
      </i-col>
      <i-col span="6" class="align-right colHeigth">
        <Checkbox @on-change="changeStatus">被移除单位</Checkbox>
      </i-col>
      <i-col span="24">
        <Table
          border
          ref="organTable"
          :loading="searching"
          :columns="tColumns"
          :data="tData"
          class="mt-1"
        >
          <div slot="contextMenu">
            <DropdownItem
              v-if="this.roleLevel == 3"
              @click.native="generateAccount"
              style="color:#19be6b"
            >批量生成账号</DropdownItem>
            <DropdownItem v-if="this.roleLevel == 1" @click.native="showVerify">查看校验码</DropdownItem>
            <DropdownItem
              v-if="this.roleLevel == 1 || this.roleLevel == 2"
              @click.native="dpartM"
            >组织管理</DropdownItem>
            <DropdownItem v-if="this.roleLevel == 3" @click.native="memberM">成员管理</DropdownItem>
          </div>
          <div slot-scope="{ row }" slot="action">
            <Button
              type="primary"
              size="small"
              style="margin-right: 0.25rem"
              @click="showCompany(row)"
            >详情</Button>
            <Button size="small" @click="HandleCompany(row,deOrRe)">{{deOrRe}}</Button>
          </div>
        </Table>
        <Page
          ref="usersPagination"
          :total="recordTotal"
          :page-size="20"
          @on-change="changePage"
          @on-page-size-change="changeSize"
          show-total
          show-sizer
          show-elevator
          class="p-1 align-right"
        />
      </i-col>
    </Row>
    <modal
      v-model="showAddWindow"
      scrollable
      footer-hide
      title="添加单位用户"
      ok-text="确定添加"
      cancel-text="取消"
      width="540"
      :mask-closable="false"
      :closable="false"
      :loading="modalLoading"
    >
      <i-form :model="companyInfo">
        <Row :gutter="5" class="px-1">
          <i-col span="24">
            <Row :gutter="8">
              <i-col span="24">
                <FormItem label="单位名称">
                  <i-input v-model="companyInfo.CompanyName"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="单位联系电话">
                  <i-input v-model="companyInfo.CompanyTel"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="联系人" style="text-align: left;">
                  <i-input v-model="companyInfo.ContactUser"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="联系人电话" style="text-align: left;">
                  <i-input v-model="companyInfo.ContactPhone" maxlength="11"></i-input>
                </FormItem>
              </i-col>
              <i-col span="24">
                <FormItem label="联系地址" style="text-align: left;">
                  <i-input v-model="companyInfo.CompanyAddress" type="textarea"></i-input>
                </FormItem>
              </i-col>
            </Row>
            <Button
              type="primary"
              style="float:right;margin-left:10px;"
              :loading="modalLoading"
              @click="insertCompany"
            >确认添加</Button>
            <Button style="float:right" @click="cancelAdd(1)">取消</Button>
          </i-col>
        </Row>
      </i-form>
    </modal>
    <modal
      v-model="showGenerate"
      scrollable
      footer-hide
      :title="companyTitle"
      ok-text="确定添加"
      cancel-text="取消"
      width="500"
      :mask-closable="false"
      :closable="false"
    >
      <i-form :model="generateInfo" :label-width="100">
        <Row :gutter="5" class="px-1">
          <i-col span="24">
            <FormItem label="生成账号前缀">
              <i-input
                v-model="generateInfo.generatePrefix"
                size="large"
                placeholder="例如：腾讯公司，前缀为首字母tx"
              ></i-input>
            </FormItem>
          </i-col>
          <i-col span="24">
            <FormItem label="生成数量">
              <i-input v-model="generateInfo.generateCount" size="large" placeholder="请输入您需要生成的数量"></i-input>
            </FormItem>
          </i-col>
          <Button
            type="primary"
            style="float:right;margin-left:10px;"
            :loading="generateStatus"
            @click="generateUsers"
          >确认添加</Button>
          <Button style="float:right" @click="cancelAdd(2)">取消</Button>
        </Row>
      </i-form>
    </modal>
  </div>
</template>

<script>
export default {
  name: "Organ",
  data() {
    return {
      verifyCode: {},
      draw: false,
      styles: {
        height: "calc(100% - 55px)",
        overflow: "auto",
        paddingBottom: "53px",
        position: "static",
      },
      companyDetail: {},
      roleLevel: 0,
      generateStatus: false,
      companyTitle: "",
      showGenerate: false,
      modalLoading: false,
      showAddWindow: false,
      pageSize: 20,
      recordTotal: 0,
      pageNum: 1,
      generateInfo: {
        generatePrefix: "",
        generateCount: "",
      },
      companyInfo: {
        CompanyName: "",
        CompanyTel: "",
        CompanyAddress: "",
        ContactUser: "",
        ContactPhone: "",
        RegisterTime: "",
      },
      tData: [],
      inputValue: "",
      searching: true,
      deOrRe: "移除",
      theight: window.innerHeight - 175,
      isShow: true,
      recordTotal: 20,
      tColumns: [
        // {
        //   type: "selection",
        //   width: 35,
        //   align: "center",
        // },
        {
          title: "单位名称",
          key: "companyName",
          minWidth: 200,
          ellipsis: true,
        },
        // {
        //   title: "单位人员",
        //   key: "userCount",
        //   minWidth: 20,
        // },
        {
          title: "联系人",
          key: "contactUser",
          minWidth: 80,
        },
        {
          title: "联系人电话",
          key: "contactPhone",
          minWidth: 120,
        },
        {
          title: "联系地址",
          key: "companyAddress",
          minWidth: 160,
          ellipsis: true,
          tooltip: true,
        },
        {
          title: "单位联系电话",
          key: "companyTel",
          minWidth: 120,
        },
        // {
        //   title: "单位注册时间",
        //   key: "registerTime",
        //   minWidth: 100,
        //   render: (h, params) => {
        //     let dt = this.myjs.subDate(params.row.registerTime);
        //     return h("span", dt);
        //   },
        //   sortable: true,
        //   sortType: "desc",
        // },
        {
          title: "操作",
          slot: "action",
          width: 102,
        },
      ],
      bindStatus: 1,
      companyId: "",
      verifyStatus: false,
    };
  },
  mounted() {
    window.addEventListener(
      "resize",
      () => (this.theight = window.innerHeight - 175),
      false
    );
    this.getCompanyList();
  },
  methods: {
    showCompany(row) {
      this.$router.push({
        path: "UnitDetail",
        query: { unitId: row.companyId },
      });
    },
    updateCompany() {
      let data = this.companyDetail;
      this.$post("Customer/UpdateCompany", data).then((res) => {
        if (res.data.code) {
          this.$Message.success(res.data.msg);
          this.draw = false;
        } else {
          this.$Modal.error({
            title: "提示",
            content: "修改信息失败，请重试",
          });
        }
      });
    },
    generateUsers() {
      this.generateStatus = true;
      if (this.generateInfo.generatePrefix == "") {
        this.generateStatus = false;
        this.$Message.error({
          content: "请填写前缀",
          duration: 3,
        });
        return;
      }
      if (this.generateInfo.generateCount == 0) {
        this.generateStatus = false;
        this.$Message.error({
          content: "请输入数量",
          duration: 3,
        });
        return;
      }
      this.$post("Customer/GenerateUser", {
        companyId: this.companyId,
        generatePrefix: this.generateInfo.generatePrefix,
        generateCount: parseInt(this.generateInfo.generateCount),
      })
        .then((res) => {
          this.generateStatus = false;
          if (res.data.code) {
            this.$Modal.success({
              title: "批量生成反馈",
              content: "添加成功",
            });
            this.showGenerate = false;
          } else {
            this.$Modal.error({
              title: "批量生成反馈",
              content: "添加失败，请重试",
            });
          }
        })
        .catch((error) => {
          this.generateStatus = false;
        });
    },
    insertCompany() {
      this.modalLoading = true;
      this.companyInfo.RegisterTime = this.myjs.formatGMT(
        this.companyInfo.RegisterTime
      );
      if (
        this.companyInfo.CompanyName == "" ||
        this.companyInfo.CompanyTel == "" ||
        this.companyInfo.CompanyAddress == "" ||
        this.companyInfo.ContactUser == "" ||
        this.companyInfo.ContactPhone == "" ||
        this.companyInfo.RegisterTime == ""
      ) {
        this.modalLoading = false;
        this.$Message.error({
          content: "请将全部信息填写完整",
          duration: 3,
        });
        return;
      }
      this.$post("Customer/InsertCompany", {
        CompanyName: this.companyInfo.CompanyName,
        CompanyTel: this.companyInfo.CompanyTel,
        CompanyAddress: this.companyInfo.CompanyAddress,
        ContactUser: this.companyInfo.ContactUser,
        ContactPhone: this.companyInfo.ContactPhone,
        Level: 1,
      })
        .then((res) => {
          this.modalLoading = false;
          if (res.data.code) {
            this.$Message.success({
              content: "添加成功",
              duration: 2,
            });
            this.showAddWindow = false;
            this.getCompanyList();
          } else {
            this.$Message.error({
              content: "添加失败，请重试",
              duration: 2,
            });
          }
        })
        .catch((error) => {
          this.modalLoading = false;
        });
    },
    cancelAdd(type) {
      if (type == 1) {
        this.showAddWindow = false;
      } else if (type == 2) {
        this.showGenerate = false;
      } else if (type == 3) {
        this.verifyStatus = false;
      }
    },
    addCompany() {
      this.showAddWindow = true;
    },
    getCompanyList() {
      this.$get("Customer/GetCompanyCustomerList", {
        keyword: this.inputValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        statusCode: this.bindStatus,
      }).then((res) => {
        this.tData = res.data.responseList;
        this.recordTotal = res.data.total;
        this.searching = false;
        this.$Message.success({
          content: "加载成功",
          duration: 2,
        });
      });
    },
    changeSize(e) {
      this.pageSize = e;
      this.getCompanyList();
    },
    changePage(e) {
      this.pageNum = e;
      this.getCompanyList();
    },
    changeStatus(k) {
      if (k) {
        this.deOrRe = "还原";
        this.isShow = false;
        this.bindStatus = 0;
      } else {
        this.deOrRe = "移除";
        this.isShow = true;
        this.bindStatus = 1;
      }
      this.pageNum = 1;
      this.getCompanyList();
    },
    dpartM() {
      this.$router.push({ name: "Depart" });
    },
    memberM() {
      this.$router.push({
        path: "Users",
        query: { companyId: this.companyId },
      });
    },
  },
};
</script>

<style lang="less" scoped="scoped">
</style>
